import React, { useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Header,
  Message,
  Segment,
  Table,
} from "semantic-ui-react";

import { createReportUrl, getReportDetailsUrl } from "utils/urls";
import { DONE, FAILED } from "utils/reportStatuses";
import { useApiCall, useUser } from "utils/hooks";
import { getReports } from "utils/api";
import ReportStatusLabel from "components/ReportStatusLabel";

function ReportsTable({ reports }) {
  const history = useHistory();

  if (reports.error) {
    return (
      <Container text>
        <Message negative>
          <Message.Header>There was an error loading reports</Message.Header>
          <p>{(reports.error.error || reports.error).toString()}</p>
        </Message>
        <Divider hidden />
      </Container>
    );
  }

  return (
    <Segment
      loading={!reports.loaded || reports.loading}
      className="efi efi-no-space-around"
      basic
    >
      <Table columns={3} textAlign="center" selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Updated</Table.HeaderCell>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {reports.data.map((report, index) => (
            <Table.Row
              className="efi efi-cursor-pointer"
              role="button"
              error={report.status === FAILED}
              positive={report.status === DONE}
              onClick={() =>
                !reports.loading &&
                reports.loaded &&
                history.push(getReportDetailsUrl({ id: report.id }))
              }
            >
              <Table.Cell>
                {report.updatedAt &&
                  new Date(report.updatedAt).toLocaleString()}
              </Table.Cell>
              <Table.Cell>{report.title}</Table.Cell>
              <Table.Cell>
                <ReportStatusLabel
                  loading={!reports.loaded || reports.loading}
                  report={report}
                  labelProps={{ as: Link }}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  );
}

ReportsTable.defaultProps = {
  reports: {},
};

function Reports() {
  const history = useHistory();
  const user = useUser();

  // Start with empty reports so an empty table is shown on load
  const reports = useApiCall(getReports, [{}, {}, {}, {}, {}]);

  // Load reports
  useEffect(() => {
    if (!user.loaded || reports.loading) {
      return;
    }

    const { cityId } = user.data;

    // Don't re-load if same city was already requested
    if (reports.loaded && cityId === reports.lastArgs[0].cityId) {
      return;
    }

    return reports.load({ cityId }).then(({ data, error }) => {
      if (!data.length) {
        history.push(createReportUrl);
      }
    });
  }, [user, history, reports]);

  return (
    <Container>
      <Container text>
        <Header as="h1" textAlign="center">
          Reports
        </Header>
      </Container>
      <Divider hidden section />
      <ReportsTable reports={reports} />
      <Divider hidden />
      <Segment textAlign="center" basic>
        <Button as={Link} to={createReportUrl} positive centered>
          Request a New Report
        </Button>
      </Segment>
    </Container>
  );
}

export default Reports;
