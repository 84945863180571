import React, { useEffect, useMemo } from "react";
import {
  Button,
  Container,
  Header,
  Label,
  Loader,
  Popup,
  Table,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import { useApiCall } from "utils/hooks";
import { getReportImage } from "utils/api";
import { localeDate } from "utils/date";

function ReportMapPopup({ reportMapApi, setExpandedImage }) {
  const {
    popupElRef,
    selectedFeature,
    selectedImage,
    setSelectedImage,
    featureHistory,
    outputFiles,
    onSetOutputFileFeature,
    onPrevFeatureHistory,
    onNextFeatureHistory,
  } = reportMapApi;

  const { properties = {}, layer } = selectedFeature;
  const { name, defectSeverity = 0, defects = [], images = [] } = properties;

  const [backHistory, forwardHistory] = featureHistory;

  const image = images[selectedImage] || {};
  const imageName = image.name || "Unknown";
  const imageDisplayId = image.displayId || image.id;

  const imageRequest = useApiCall(getReportImage);

  const defectsById = useMemo(() => {
    const defectsById = {};
    defects.forEach((defect) => {
      defectsById[defect.id] = defect;
    });
    return defectsById;
  }, [defects]);

  const imageDefects = useMemo(() => {
    const imageDefects = [];
    (image.defects || []).forEach(({ id }) => {
      const defect = defectsById[id];
      if (defect) {
        imageDefects.push(defect);
      }
    });
    return imageDefects;
  }, [image, defectsById]);

  // Load image URL
  useEffect(() => {
    if (
      !image ||
      imageRequest.loading ||
      (imageRequest.lastArgs && imageRequest.lastArgs[0].id === imageDisplayId)
    ) {
      return;
    }

    imageRequest.load({ id: imageDisplayId });
  });

  // Return nothing if popup is closed
  if (!popupElRef.current || !selectedFeature) {
    return null;
  }

  const outputFile = outputFiles.byId[selectedFeature.layer.id] || {};

  const {
    imageReportOutputFileId,
    defectReportOutputFileId,
    locationReportOutputFileId,
  } = outputFile;

  const downloadUrl =
    imageRequest.data && image && imageRequest.data.id === imageDisplayId
      ? imageRequest.data.downloadUrl
      : undefined;

  return React.createPortal(
    <Container className="efi efi-map-popup" textAlign="center" text>
      <Label className="efi efi-no-padding" attached="top" color="blue">
        <Popup
          position="top center"
          content={
            <>
              <b>Defect Severity</b>: {Math.round(defectSeverity * 100)}%
            </>
          }
          trigger={
            <Button.Group color="blue" size="mini" fluid>
              <Button
                className="efi efi-map-popup-history-button"
                icon="chevron left"
                disabled={!backHistory.length}
                onClick={onPrevFeatureHistory}
              />
              <Button
                as="div"
                className="efi efi-no-pointer-events"
                tabIndex={-1}
              >
                {name || properties.id}
              </Button>
              <Button
                className="efi efi-map-popup-history-button"
                icon="chevron right"
                disabled={!forwardHistory.length}
                onClick={onNextFeatureHistory}
              />
            </Button.Group>
          }
        />
      </Label>
      <div className="efi efi-map-popup-image-section">
        <Header as="h3">
          {locationReportOutputFileId &&
          (image.locationId !== properties.id ||
            locationReportOutputFileId !== layer.id) ? (
            <Link
              onClick={() =>
                onSetOutputFileFeature(
                  locationReportOutputFileId,
                  image.locationId
                )
              }
            >
              {imageName}
            </Link>
          ) : (
            imageName
          )}
        </Header>
        <div className="efi efi-map-popup-image-container">
          {downloadUrl ? (
            <img
              className={`efi efi-map-popup-image ${
                imageReportOutputFileId &&
                (image.id !== properties.id ||
                  imageReportOutputFileId !== layer.id)
                  ? "efi-cursor-pointer"
                  : ""
              }`}
              onClick={() =>
                onSetOutputFileFeature(imageReportOutputFileId, image.id)
              }
              src={downloadUrl}
              alt={imageName}
            />
          ) : (
            <Loader active />
          )}
          <Button.Group
            className="efi efi-map-popup-image-button"
            floated="right"
          >
            <Button
              icon="expand"
              onClick={() => setExpandedImage(downloadUrl)}
            />
          </Button.Group>
        </div>
        {images.length > 1 ? (
          <div>
            <div className="efi efi-map-popup-image-controls">
              <Button.Group>
                <Button
                  icon="angle double left"
                  disabled={selectedImage === 0}
                  onClick={() => setSelectedImage(0)}
                  basic
                  compact
                />
                <Button
                  icon="angle left"
                  disabled={selectedImage === 0}
                  onClick={() => setSelectedImage(selectedImage - 1)}
                  basic
                  compact
                />
                <Button
                  as="div"
                  className="efi efi-no-pointer-events"
                  tabIndex={-1}
                  basic
                  compact
                >
                  {localeDate(image.takenAt)}
                </Button>
                <Button
                  icon="angle right"
                  disabled={selectedImage === images.length - 1}
                  onClick={() => setSelectedImage(selectedImage + 1)}
                  basic
                  compact
                />
                <Button
                  icon="angle double right"
                  disabled={selectedImage === images.length - 1}
                  onClick={() => setSelectedImage(images.length - 1)}
                  basic
                  compact
                />
              </Button.Group>
            </div>
          </div>
        ) : image ? (
          <div className="efi efi-map-popup-image-controls">
            {localeDate(image.takenAt)}
          </div>
        ) : null}
      </div>
      <div className="efi efi-map-popup-defects">
        <div className="efi efi-map-popup-scroll">
          <Header as="h3">Defects</Header>
          {imageDefects.length ? (
            <Table textAlign="center" celled compact selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>ID</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Severity</Table.HeaderCell>
                  <Table.HeaderCell>Detected</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {imageDefects.map((defect) => (
                  <Popup
                    position="bottom center"
                    disabled={!Object.keys(defect.properties || {}).length}
                    content={
                      <div>
                        {Object.keys(defect.properties || {}).map((key) => (
                          <div>
                            <b>{key}</b>: {defect.properties[key]}
                          </div>
                        ))}
                      </div>
                    }
                    trigger={
                      <Table.Row key={defect.id}>
                        <Table.Cell>
                          {defectReportOutputFileId &&
                          (defect.id !== properties.id ||
                            defectReportOutputFileId !== layer.id) ? (
                            <Link
                              onClick={() =>
                                onSetOutputFileFeature(
                                  defectReportOutputFileId,
                                  defect.id
                                )
                              }
                            >
                              {defect.id}
                            </Link>
                          ) : (
                            defect.id
                          )}
                        </Table.Cell>
                        <Table.Cell>{defect.type}</Table.Cell>
                        <Table.Cell>
                          {`${Math.round((defect.defectSeverity || 0) * 100)}%`}
                        </Table.Cell>
                        <Table.Cell>
                          {imageReportOutputFileId &&
                          (defect.firstSeenInImageId !== properties.id ||
                            imageReportOutputFileId !== layer.id) ? (
                            <Link
                              onClick={() =>
                                onSetOutputFileFeature(
                                  imageReportOutputFileId,
                                  defect.firstSeenInImageId
                                )
                              }
                            >
                              {localeDate(defect.firstSeenAt)}
                            </Link>
                          ) : (
                            localeDate(defect.firstSeenAt)
                          )}
                        </Table.Cell>
                      </Table.Row>
                    }
                  />
                ))}
              </Table.Body>
            </Table>
          ) : (
            <div>No defects in selected image.</div>
          )}
        </div>
      </div>
    </Container>,
    popupElRef.current
  );
}

ReportMapPopup.defaultProps = {
  selectedFeature: {},
};

function ReportMapPopupFullscreen({ image, setImage, selectedFeature }) {
  // Close on pressing escape
  useEffect(() => {
    const onKeyDown = ({ keyCode }) => {
      if (keyCode === 27) {
        setImage(false);
      }
    };

    if (!image) {
      return;
    }

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [image, setImage]);

  if (!image || !selectedFeature) {
    return null;
  }

  return (
    <div className="efi efi-map-popup-fullscreen">
      <Button
        className="efi efi-map-popup-fullscreen-close-button"
        icon="close"
        onClick={() => setImage(false)}
        inverted
      />
      <img
        className="efi efi-map-popup-fullscreen-image efi-centered"
        alt="fullscreen"
        src={image}
      />
    </div>
  );
}

export { ReportMapPopup, ReportMapPopupFullscreen };
