import React, { useState } from "react";
import { Button, Divider, Form, Icon, Input, Segment } from "semantic-ui-react";

import { POINT_MAP, POLYGON_MAP } from "utils/reportOutputTypes";
import { defectTypeSortOrder } from "utils/defectTypes";

function ReportMapControls({ reportMapApi }) {
  const [isOpen, setIsOpen] = useState(true);

  const {
    selectedPointMaps,
    setSelectedPointMaps,
    selectedPolygonMaps,
    setSelectedPolygonMaps,
    defects,
    setDefects,
    showAllPoints,
    setShowAllPoints,
    minSeverity,
    setMinSeverity,
    maxSeverity,
    setMaxSeverity,
    outputFiles,
  } = reportMapApi;

  // Map not yet initialized
  if (!defects) {
    return null;
  }

  // Defect controls should only be available if defects are in the map
  const shouldEnableDefectControls = Object.keys(defects).length > 0;

  return (
    <Segment className="efi efi-map-overlay" textAlign="left">
      <Form>
        <Button.Group floated="right">
          <Button
            icon={isOpen ? "left chevron" : "right chevron"}
            className="efi efi-report-details-controls-open-button"
            onClick={() => setIsOpen(!isOpen)}
            basic
          />
        </Button.Group>
        {isOpen ? (
          <>
            {outputFiles.byType[POINT_MAP].length > 0 ? (
              <>
                <Form.Group
                  className="efi efi-report-details-checkboxes-group"
                  grouped
                >
                  <label>Display Points</label>
                  <div className="efi efi-report-details-checkboxes">
                    {outputFiles.byType[POINT_MAP].map(({ name }, index) => (
                      <Form.Checkbox
                        key={index}
                        label={name}
                        checked={selectedPointMaps[index]}
                        onChange={() =>
                          setSelectedPointMaps({
                            ...selectedPointMaps,
                            [index]: !selectedPointMaps[index],
                          })
                        }
                      />
                    ))}
                  </div>
                </Form.Group>
                <Divider />
              </>
            ) : null}
            {outputFiles.byType[POLYGON_MAP].length > 0 ? (
              <>
                <Form.Group
                  className="efi efi-report-details-checkboxes-group"
                  grouped
                >
                  <label>Display Shapes</label>
                  <div className="efi efi-report-details-checkboxes">
                    {outputFiles.byType[POLYGON_MAP].map(({ name }, index) => (
                      <Form.Checkbox
                        key={index}
                        label={name}
                        checked={selectedPolygonMaps[index]}
                        onChange={() =>
                          setSelectedPolygonMaps({
                            ...selectedPolygonMaps,
                            [index]: !selectedPolygonMaps[index],
                          })
                        }
                      />
                    ))}
                  </div>
                </Form.Group>
                <Divider />
              </>
            ) : null}
            {shouldEnableDefectControls && (
              <>
                <Form.Group
                  className="efi efi-report-details-checkboxes-group"
                  grouped
                >
                  <label>Display Defects</label>
                  <div className="efi efi-report-details-checkboxes">
                    {Object.keys(defects)
                      .sort((type1, type2) => {
                        const order1 = defectTypeSortOrder[type1] || 99;
                        const order2 = defectTypeSortOrder[type2] || 99;
                        return order1 < order2 ? -1 : order1 > order2;
                      })
                      .map((defectType) => (
                        <Form.Checkbox
                          key={defectType}
                          label={defectType}
                          checked={defects[defectType]}
                          onChange={() =>
                            setDefects({
                              ...defects,
                              [defectType]: !defects[defectType],
                            })
                          }
                        />
                      ))}
                  </div>
                </Form.Group>
                <Divider />
                <Form.Checkbox
                  label="Display Items Without Defects"
                  checked={showAllPoints}
                  onChange={() => setShowAllPoints(!showAllPoints)}
                />
              </>
            )}
            <Form.Group grouped>
              <Form.Field>
                <label>Display Severity Range</label>
              </Form.Field>
              <Form.Group>
                <Input
                  type="number"
                  placeholder="0"
                  className="efi efi-report-details-controls-severity-input"
                  value={minSeverity}
                  min={0}
                  max={100}
                  onChange={({ target: { value } }) => {
                    if (
                      maxSeverity !== "" &&
                      Number(value) > Number(maxSeverity)
                    ) {
                      setMaxSeverity(value);
                    }
                    setMinSeverity(value);
                  }}
                />
                <Input
                  type="number"
                  placeholder="100"
                  className="efi efi-report-details-controls-severity-input"
                  value={maxSeverity}
                  min={0}
                  max={100}
                  onChange={({ target: { value } }) => {
                    if (value !== "" && Number(value) < Number(minSeverity)) {
                      setMinSeverity(value);
                    }
                    setMaxSeverity(value);
                  }}
                />
              </Form.Group>
            </Form.Group>
            <Divider />
            <Icon name="info circle" /> <i>Click to select points</i>
          </>
        ) : null}
      </Form>
    </Segment>
  );
}

export default ReportMapControls;
