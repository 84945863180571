import StackdriverErrorReporter from "stackdriver-errors-js";

import config from "utils/config";

const { gcpProjectId, gcpApiKey } = config;

const errorReporter = new StackdriverErrorReporter();

const shouldReportErrors = gcpProjectId && gcpApiKey;

if (shouldReportErrors) {
  errorReporter.start({
    projectId: gcpProjectId,
    key: gcpApiKey,
  });
} else {
  console.warn("WARNING: error reporting is not configured");
}

function reportError(error) {
  if (shouldReportErrors) {
    errorReporter.report(error);
  }
}

export default errorReporter;

export { reportError };
