import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Footer from "components/Footer";
import Navigation from "components/Navigation";
import Page from "components/Page";
import Routes from "components/Routes";
import UserProvider from "components/UserProvider";

import config from "utils/config";
import { Auth0Provider } from "utils/auth0";
import { reportsUrl } from "utils/urls";

import "./app.less";

const { apiUrl, auth0Domain, auth0ClientId } = config;

function App() {
  return (
    <div className="efi efi-app">
      <Router>
        <Auth0Provider
          domain={auth0Domain}
          clientId={auth0ClientId}
          redirectUri={`${window.location.origin}${reportsUrl}`}
          audience={apiUrl}
        >
          <UserProvider>
            <Navigation />
            <Page>
              <Routes />
            </Page>
            <Footer />
          </UserProvider>
        </Auth0Provider>
      </Router>
    </div>
  );
}

export default App;
