import React, { useEffect } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { Container, Divider, Message } from "semantic-ui-react";

import NotFound from "pages/NotFound";
import Reports from "pages/Reports";
import CreateReport from "pages/reports/CreateReport";
import ReportDetails from "pages/reports/ReportDetails";

import { useAuth0 } from "utils/auth0";
import { useUser } from "utils/hooks";

import {
  homeUrl,
  reportsUrl,
  createReportUrl,
  reportDetailsUrl,
} from "utils/urls";

function Routes() {
  const { loginWithRedirect, isLoading, isAuthenticated, error } = useAuth0();
  const user = useUser();

  useEffect(() => {
    if (!isAuthenticated && !isLoading && !error) {
      loginWithRedirect();
    }
  });

  if (error || user.error) {
    return (
      <Container text>
        <Divider section hidden />
        <Message negative>
          <Message.Header>There was an error authenticating</Message.Header>
          <p>Please try again later</p>
        </Message>
      </Container>
    );
  }

  return (
    <Switch>
      <Redirect
        exact
        preserveQueryString
        from={`${homeUrl}/`}
        to={reportsUrl}
      />
      <Route exact path={reportsUrl}>
        <Reports />
      </Route>
      <Route exact path={createReportUrl}>
        <CreateReport />
      </Route>
      <Route exact path={reportDetailsUrl}>
        <ReportDetails />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}

export default Routes;
