const DONE = "done";
const FAILED = "failed";
const IN_PROGRESS = "in-progress";
const UNINITIALIZED = "uninitialized";

const icons = {
  [DONE]: "check circle",
  [FAILED]: "warning circle",
  [IN_PROGRESS]: "clock",
  [UNINITIALIZED]: "clock",
};

const labels = {
  [DONE]: "Done",
  [FAILED]: "Failed",
  [IN_PROGRESS]: "In Progress",
  [UNINITIALIZED]: "Uninitialized",
};

export { DONE, FAILED, IN_PROGRESS, icons, labels };
