import React from "react";
import { Container, Divider, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { homeUrl } from "utils/urls";

function NotFound() {
  return (
    <Container className="efi efi-centered" text>
      <Header as="h1">Page Not Found</Header>
      <Divider hidden section />
      <Header as="h3">
        Check that the URL is correct and try again or{" "}
        <Link to={homeUrl}>return to the home page</Link>.
      </Header>
    </Container>
  );
}

export default NotFound;
