import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";

import "mapbox-gl/dist/mapbox-gl.css";

import config from "utils/config";

import "./map.less";

mapboxgl.accessToken = config.mapboxAccessToken;

function Map({ mapOptions, containerProps, mapRef: mapRefProp, children }) {
  const containerRef = useRef();
  const mapRef = useRef();

  // Warn on unsupported containerProps value
  useEffect(() => {
    if (containerProps.ref && !mapOptions.container) {
      console.warn(
        'Warning: cannot set prop "ref" in "containerProps" of <Map />'
      );
    }
  }, [containerProps.ref, mapOptions.container]);

  // Set up mapbox
  useEffect(() => {
    if ((containerRef.current || mapOptions.container) && !mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: containerRef.current,
        ...mapOptions,
      });

      if (typeof mapRefProp === "function") {
        mapRefProp(mapRef.current);
      } else if (typeof mapRefProp === "object") {
        mapRefProp.current = mapRef.current;
      }
    }
  }, [mapOptions, mapRefProp]);

  if (mapOptions.container) {
    return children;
  }

  return (
    <div
      {...containerProps}
      className={`efi efi-map-container ${containerProps.classname || ""}`}
      ref={containerRef}
    >
      {children}
    </div>
  );
}

Map.defaultProps = {
  mapOptions: {},
  containerProps: {},
  children: null,
};

export default Map;
