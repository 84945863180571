import { createContext, useState, useCallback, useContext } from "react";
import { useAuth0 } from "utils/auth0";

function usePromise(getPromise, initialData) {
  const [lastArgs, setLastArgs] = useState();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(initialData);

  const load = useCallback(
    (...args) => {
      setLoading(true);
      setError();
      setLastArgs(args);
      return getPromise(...args)
        .then((newData) => {
          setLoaded(true);
          setLoading(false);
          setData(newData);
          return { data: newData };
        })
        .catch((newError) => {
          setLoaded(true);
          setLoading(false);
          setError(newError);
          return { data, error: newError };
        });
    },
    [data, getPromise]
  );

  const clearError = () => setError();

  return {
    load,
    loaded,
    loading,
    error,
    clearError,
    data,
    lastArgs,
  };
}

function useApiCall(getPromise, initialData, { includeToken = true } = {}) {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const promiseHook = usePromise((...args) => {
    if (!includeToken || !isAuthenticated) {
      return getPromise(...args);
    }
    return getAccessTokenSilently().then((token) =>
      getPromise(...args, { Authorization: `Bearer ${token}` })
    );
  }, initialData);

  return { ...promiseHook, isAuthenticated, auth0User: user };
}

const UserContext = createContext({});

const useUser = () => useContext(UserContext);

export { UserContext, usePromise, useApiCall, useUser };
