import React, { useRef } from "react";
import { Button, Icon, Segment } from "semantic-ui-react";

function FileInputFiles({ files, groupProps, fileProps }) {
  if (!files || !files.length) {
    return null;
  }

  return (
    <Segment.Group {...groupProps}>
      {files.map((file) => {
        const name = file.name || file;
        return (
          <Segment {...fileProps} key={name}>
            <Icon name="file" size="large" /> {name}
          </Segment>
        );
      })}
    </Segment.Group>
  );
}

FileInputFiles.defaultProps = {
  files: [],
  groupProps: {},
  fileProps: {},
};

function FileInput({ buttonProps, inputProps, files }) {
  const fileRef = useRef(null);

  return (
    <>
      <Button
        type="button"
        size="medium"
        onClick={() => {
          if (fileRef.current) {
            fileRef.current.click();
          }
        }}
        {...buttonProps}
      />
      <input type="file" ref={fileRef} hidden {...inputProps} />
    </>
  );
}

FileInput.defaultProps = {
  buttonProps: {},
  inputProps: {},
};

FileInput.Files = FileInputFiles;

export default FileInput;
