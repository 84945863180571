import React, { useState } from "react";
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Loader,
  Message,
  Segment,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import Map from "components/Map";
import { EXPORT_FILE } from "utils/reportOutputTypes";
import { reportsUrl } from "utils/urls";

import { ReportRequestDetailsModal } from "./ReportRequestDetails";
import ReportMapControls from "./ReportMapControls";
import { ReportMapPopup, ReportMapPopupFullscreen } from "./ReportMapPopup";
import { useReportMap } from "./reportMapHooks";

function Report({ report }) {
  const reportMapApi = useReportMap(report);

  const [expandedImage, setExpandedImage] = useState(false);

  const {
    error,
    loaded,
    user,
    mapOptions,
    setMapRef,
    defects,
    selectedFeature,
    gisExport,
    outputFiles,
  } = reportMapApi;

  return (
    <Container className="efi efi-report">
      <Header as="h2" textAlign="center">
        {report.data.title}
      </Header>
      <Divider hidden />
      {error ? (
        <Container text>
          <Message negative>
            <Message.Header>There was an error loading the map</Message.Header>
            <p>{error.toString()}</p>
          </Message>
        </Container>
      ) : (
        <Segment className="efi efi-no-space-around" textAlign="center">
          <div className="efi efi-report-map-container">
            {user.loaded ? (
              <Map mapOptions={mapOptions} mapRef={setMapRef}>
                <Loader active={!loaded || !defects} />
                <ReportMapControls reportMapApi={reportMapApi} />
                <ReportMapPopup
                  reportMapApi={reportMapApi}
                  setExpandedImage={setExpandedImage}
                />
              </Map>
            ) : (
              <Loader active />
            )}
          </div>
        </Segment>
      )}
      <ReportMapPopupFullscreen
        image={expandedImage}
        setImage={setExpandedImage}
        selectedFeature={selectedFeature}
      />
      <Container>
        <Segment textAlign="center" basic>
          {gisExport.error ? (
            <Container text>
              <Message negative>
                <Message.Header>
                  There was an error downloading the GeoJSON file
                </Message.Header>
                <p>{gisExport.error.toString()}</p>
              </Message>
            </Container>
          ) : null}
          <Grid
            columns={outputFiles.byType[EXPORT_FILE].length ? 3 : 2}
            doubling
            centered
            padded
          >
            <Grid.Column>
              <Button as={Link} to={reportsUrl} fluid>
                Return to browsing reports
              </Button>
            </Grid.Column>
            <Grid.Column>
              <ReportRequestDetailsModal
                report={report}
                trigger={<Button fluid>View report request details</Button>}
              />
            </Grid.Column>
            {outputFiles.byType[EXPORT_FILE].length ? (
              <Grid.Column>
                <Button
                  as="a"
                  active={!gisExport.loading}
                  loading={gisExport.loading}
                  onClick={gisExport.load}
                  fluid
                  primary
                >
                  Export GIS Files
                </Button>
              </Grid.Column>
            ) : null}
          </Grid>
        </Segment>
      </Container>
    </Container>
  );
}

Report.defaultProps = { report: {} };

export default Report;
