function parseReportFeature(feature) {
  const { properties } = feature;
  const { defects, images } = properties;

  return {
    ...feature,
    geometry: feature.geometry,
    properties: {
      ...properties,
      defects: typeof defects === "string" ? JSON.parse(defects) : defects,
      images: typeof images === "string" ? JSON.parse(images) : images,
    },
  };
}

export { parseReportFeature };
