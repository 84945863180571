import axios, { create as createAxios } from "axios";

import config from "utils/config";
import { reportError } from "utils/errors";
import { IN_PROGRESS, FAILED } from "utils/reportStatuses";

const api = createAxios({
  baseURL: config.apiUrl,
});

// Helpers

function uploadFile({ fields, url }, file) {
  const formData = new FormData();

  Object.keys(fields).forEach((field) => {
    formData.set(field, fields[field]);
  });

  formData.set("file", file, file.name);

  return axios.post(url, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

function handleAxiosError(error) {
  let formattedError = error;

  if (error.response) {
    if (error.response.data && error.response.data.error) {
      formattedError = error.response.data.error;
    } else if (error.response.status) {
      formattedError = new Error(
        `The server responded with error code ${error.response.status}`
      );
    }
  }

  reportError(formattedError);

  throw formattedError;
}

// API functions

const getUser = ({ id, auth0Id }, headers) =>
  api
    .get("user", {
      params: { id, auth0Id },
      headers,
    })
    .then(({ data }) => data)
    .catch(handleAxiosError);

const isReportTitleUnique = ({ title, cityId }, headers) =>
  api
    .get("reports", {
      params: {
        title,
        cityId,
      },
      headers,
    })
    .then(({ data }) => data.length === 0)
    .catch(handleAxiosError);

const createReport = (report = {}, headers) =>
  api
    .post(
      "report",
      {
        ...report,
        inputFiles: (report.inputFiles || []).map(({ name }) => ({ name })),
      },
      { params: { includeInputUploadPolicies: true }, headers }
    )
    .then(async (response) => {
      try {
        await Promise.all(
          response.data.inputFiles.map(({ uploadPolicy }, index) =>
            uploadFile(uploadPolicy, report.inputFiles[index])
          )
        );

        await api.put(
          "report",
          { id: response.data.id, status: IN_PROGRESS },
          { headers }
        );
      } catch (e) {
        await api.put(
          "report",
          { id: response.data.id, status: FAILED },
          { headers }
        );
        throw e;
      }
    })
    .catch(handleAxiosError);

const getReports = ({ cityId } = {}, headers) =>
  api
    .get("reports", {
      params: { cityId },
      headers,
    })
    .then(({ data }) => data)
    .catch(handleAxiosError);

const getReport = ({ id }, headers) =>
  api
    .get("report", {
      params: { id, includeOutputDownloadUrls: true },
      headers,
    })
    .then(({ data }) => data)
    .catch(handleAxiosError);

const getReportImage = ({ id }, headers) =>
  api
    .get("report/outputImage", {
      params: { id, includeDownloadUrl: true },
      headers,
    })
    .then(({ data }) => data)
    .catch(handleAxiosError);

export {
  getUser,
  isReportTitleUnique,
  createReport,
  getReports,
  getReport,
  getReportImage,
};
