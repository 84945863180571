import "utils/debug";
import "utils/errors";
import "utils/bootstrap";

import React from "react";
import { render } from "react-snapshot";
import "semantic-ui-less/semantic.less";

import App from "components/App";
import "index.less";

// Render the app with static pre-rendering
// https://github.com/geelen/react-snapshot
render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
