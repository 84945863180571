import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import {
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Label,
  Message,
  Segment,
} from "semantic-ui-react";
import * as Yup from "yup";

import FileInput from "components/FileInput";
import { isReportTitleUnique, createReport } from "utils/api";
import { useApiCall, useUser } from "utils/hooks";
import { reportsUrl } from "utils/urls";

const getValidationSchema = (checkTitle) =>
  Yup.object({
    title: Yup.string()
      .min(5, "Must be at least 5 characters")
      .max(50, "Must be 50 characters or less")
      .required("Required")
      .test("isReportTitleUnique", "Report title is already taken", (title) => {
        if (!title) {
          return true;
        }
        return checkTitle(title)
          .then(({ data }) => data)
          .catch((error) => {
            console.warn("Warning: got error validating report title:", error);
            return true;
          });
      }),
    inputFiles: Yup.array()
      .min(1, "Must select at least one file")
      .max(20, "Must be 20 files or less")
      .required("Required"),
    notes: Yup.string().max(1000, "Must be 1000 characters or less"),
  });

function CreateReportError({ error }) {
  if (!error) {
    return null;
  }

  return (
    <>
      <Divider hidden />
      <Message negative>
        <Message.Header>
          There was an error submitting the report
        </Message.Header>
        <p>{(error.error || error).toString()}</p>
      </Message>
    </>
  );
}

function CreateReportForm({ setSubmitted }) {
  const user = useUser();
  const submitReport = useApiCall(createReport);

  const getIsReportTitleUnique = useApiCall((title, headers) =>
    isReportTitleUnique({ title, cityId: user.data.cityId }, headers)
  );

  const validationSchema = useMemo(
    () => getValidationSchema(getIsReportTitleUnique.load),
    [getIsReportTitleUnique.load]
  );

  const formik = useFormik({
    initialValues: {},
    validate: () => {
      submitReport.clearError();
      return {};
    },
    onSubmit: (values) =>
      submitReport
        .load({ ...values, cityId: user.data.cityId })
        .then(({ error }) => {
          if (!error) {
            setSubmitted(true);
          }
        }),
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <Segment>
      <Form
        loading={user.loading || formik.isValidating || formik.isSubmitting}
        onSubmit={formik.handleSubmit}
      >
        <Form.Input
          name="title"
          label="Report title"
          placeholder="Enter in a unique name for the report"
          error={formik.errors.title}
          {...formik.getFieldProps("title")}
        />
        <Form.Field error={formik.errors.inputFiles}>
          <label>Files to process</label>
          <FileInput.Files files={formik.values.inputFiles} />
          <FileInput
            buttonProps={{
              basic: true,
              content: "Select files",
            }}
            inputProps={{
              onChange: (event) => {
                if (!event.target.files || !event.target.files.length) {
                  return;
                }
                formik.setFieldValue(
                  "inputFiles",
                  Array.from(event.target.files)
                );
              },
              multiple: true,
            }}
          />
          {formik.errors.inputFiles ? (
            <div>
              <Label
                content={formik.errors.inputFiles}
                hidden={!formik.errors.inputFiles}
                pointing="above"
                role="alert"
                active
                prompt
              />
            </div>
          ) : null}
        </Form.Field>
        <Form.TextArea
          name="notes"
          label="Notes"
          placeholder="Enter any additional details about the included files"
          error={formik.errors.notes}
          {...formik.getFieldProps("notes")}
        />
        <CreateReportError error={submitReport.error} />
        <Divider hidden />
        <Grid centered columns={2}>
          <Grid.Column>
            <Form.Button content="Submit" fluid primary />
          </Grid.Column>
        </Grid>
      </Form>
    </Segment>
  );
}

function CreateReportSuccess() {
  return (
    <Message positive>
      <Message.Header>Report successfully requested</Message.Header>
      <p>When the report is finished processing you should receive an email.</p>
      <p>
        <Link to={reportsUrl}>Browse reports</Link>
      </p>
    </Message>
  );
}

function CreateReport() {
  const [isSubmitted, setSubmitted] = useState(false);

  return (
    <Container text>
      <Header as="h1" textAlign="center">
        Request a New Pavement Condition Report
      </Header>
      <Header as="h3" textAlign="center">
        Street images and geolocation data will be processed and checked for
        pavement defects.
      </Header>
      <Divider hidden section />
      {isSubmitted ? (
        <CreateReportSuccess />
      ) : (
        <CreateReportForm setSubmitted={setSubmitted} />
      )}
    </Container>
  );
}

export default CreateReport;
