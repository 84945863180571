const homeUrl = "";

const reportsUrl = `${homeUrl}/reports`;
const createReportUrl = `${reportsUrl}/new`;
const reportDetailsUrl = `${reportsUrl}/:id`;

const getReportDetailsUrl = ({ id }) => `${reportsUrl}/${id}`;

export {
  homeUrl,
  reportsUrl,
  createReportUrl,
  reportDetailsUrl,
  getReportDetailsUrl,
};
