import React from "react";
import { useLocation, Link } from "react-router-dom";
import { Container, Dropdown, Menu } from "semantic-ui-react";

import { useAuth0 } from "utils/auth0";
import { useUser } from "utils/hooks";
import { reportsUrl } from "utils/urls";

function Navigation() {
  const location = useLocation();
  const user = useUser();
  const { logout } = useAuth0();

  return (
    <Menu fixed="top" size="huge" borderless>
      <Container>
        <Menu.Item
          header
          as={location.pathname !== reportsUrl ? Link : "a"}
          to={reportsUrl}
        >
          Eye For Infrastructure
        </Menu.Item>
        <Menu.Menu position="right">
          <Dropdown
            text={user.loaded ? user.data.name : "Loading..."}
            disabled={!user.loaded}
            item
          >
            {user.loaded ? (
              <Dropdown.Menu>
                <Dropdown.Item disabled>{user.data.city.name}</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  disabled={!user.loaded}
                  onClick={() => logout({ returnTo: window.location.origin })}
                >
                  Sign out
                </Dropdown.Item>
              </Dropdown.Menu>
            ) : null}
          </Dropdown>
        </Menu.Menu>
      </Container>
    </Menu>
  );
}

export default Navigation;
