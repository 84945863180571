import React from "react";
import { Container } from "semantic-ui-react";

import "./footer.less";

function Footer() {
  return (
    <footer className="efi efi-footer">
      <Container textAlign="center">
        Copyright © Eye For Infrastructure 2020
      </Container>
    </footer>
  );
}

export default Footer;
