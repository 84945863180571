import React from "react";
import {
  Auth0Provider as RealAuth0Provider,
  useAuth0 as realUseAuth0,
} from "@auth0/auth0-react";

// This file mocks auth0-react in non-browser environments for static pre-rendering

const hasAuth0 = Boolean(window.crypto);

function Auth0Provider(props) {
  if (hasAuth0) {
    return <RealAuth0Provider {...props} />;
  }
  return props.children;
}

function useAuth0(...args) {
  if (hasAuth0) {
    return realUseAuth0(...args);
  }
  return {
    isLoading: true,
    isAuthenticated: false,
    loginWithRedirect: () => null,
    getAccessTokenSilently: () => "",
  };
}

export { Auth0Provider, useAuth0 };
