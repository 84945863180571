import React from "react";
import { Icon, Label } from "semantic-ui-react";

import {
  DONE,
  FAILED,
  icons as statusIcons,
  labels as statusLabels,
} from "utils/reportStatuses";

function ReportStatusLabel({ loading, report: { id, status }, labelProps }) {
  let color;
  if (status === FAILED) {
    color = "red";
  } else if (status === DONE) {
    color = "green";
  }

  let icon;
  if (statusIcons[status]) {
    icon = statusIcons[status];
  } else if (loading) {
    icon = "ellipsis horizontal";
  }

  let label;
  if (statusLabels[status]) {
    label = statusLabels[status];
  } else if (loading) {
    label = "Loading";
  } else {
    label = status;
  }

  return (
    <Label color={color} padded {...labelProps}>
      {icon ? <Icon name={icon} size="large" /> : null}
      {label}
    </Label>
  );
}

ReportStatusLabel.defaultProps = {
  report: {},
  labelProps: {},
};

export default ReportStatusLabel;
