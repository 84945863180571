import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import mapboxgl from "mapbox-gl";
import { saveAs } from "file-saver";

import { parseReportFeature } from "utils/reportFeatures";
import { usePromise, useUser } from "utils/hooks";
import { POINT_MAP, POLYGON_MAP, EXPORT_FILE } from "utils/reportOutputTypes";

// Constant helper variables

const reportFeatureCircleRadius = {
  base: 4,
  stops: [
    [12, 4],
    [22, 180],
  ],
};

const defaultPointColor = "rgb(0, 0, 255)";
const minDefectPointColor = "rgb(136, 0, 136)";
const maxDefectPointColor = "rgb(255, 0, 0)";

const defaultPolyColor = "rgba(0, 0, 255, 0.25)";
const minDefectPolyColor = "rgba(136, 0, 136, 0.25)";
const maxDefectPolyColor = "rgba(255, 0, 0, 0.25)";

const hideFilter = ["==", "1", "0"];

// Main hook

function useReportMap({ data }) {
  // Initialize basic hooks

  // Map refs
  const mapRef = useRef();
  const popupRef = useRef();
  const popupElRef = useRef();

  // Map state
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [featureHistory, setFeatureHistory] = useState([[], []]);

  // Controls
  const [selectedPointMaps, setSelectedPointMaps] = useState({});
  const [selectedPolygonMaps, setSelectedPolygonMaps] = useState({});
  const [showAllPoints, setShowAllPoints] = useState(true);
  const [defects, setDefects] = useState({});
  const [minSeverity, setMinSeverity] = useState(0);
  const [maxSeverity, setMaxSeverity] = useState(100);

  // Additional data
  const user = useUser();

  // Build map options

  const mapOptions = {
    style: "mapbox://styles/mapbox/streets-v11",
    zoom: 2,
  };
  if (user.loaded && user.data.city.coords) {
    mapOptions.center = [user.data.city.coords.long, user.data.city.coords.lat];
    mapOptions.zoom = 12;
  }

  // Collect output files by type

  const outputFiles = useMemo(() => {
    const outputFiles = {
      byType: { [POINT_MAP]: [], [POLYGON_MAP]: [], [EXPORT_FILE]: [] },
      byId: {},
      icons: [],
    };

    data.outputFiles.forEach((outputFile) => {
      const { id, type, icon } = outputFile;

      if (outputFiles.byType[type] === undefined) {
        outputFiles.byType[type] = [];
      }

      outputFiles.byType[type].push(outputFile);
      outputFiles.byId[id] = outputFile;

      if (icon && outputFiles.icons.indexOf(icon) < 0) {
        outputFiles.icons.push(icon);
      }
    });

    return outputFiles;
  }, [data]);

  // Set default selected maps

  useEffect(() => {
    const newSelectedPointMaps = {};
    outputFiles.byType[POINT_MAP].forEach((outputFile, index) => {
      newSelectedPointMaps[index] = outputFile.defaultOn;
    });
    setSelectedPointMaps(newSelectedPointMaps);

    const newSelectedPolygonMaps = {};
    outputFiles.byType[POLYGON_MAP].forEach((outputFile, index) => {
      newSelectedPolygonMaps[index] = outputFile.defaultOn;
    });
    setSelectedPolygonMaps(newSelectedPolygonMaps);
  }, [outputFiles]);

  // Build function for exporting GIS

  const gisExport = usePromise(
    () =>
      new Promise((resolve, reject) => {
        if (outputFiles.byType[EXPORT_FILE].length > 1) {
          console.warn(
            "WARNING: more than one export file is attached to the report, but only one is currently supported."
          );
        }
        try {
          gisExport.clearError();
          saveAs(
            outputFiles.byType[EXPORT_FILE][0].downloadUrl,
            outputFiles.byType[EXPORT_FILE][0].name
          );
          resolve();
        } catch (error) {
          console.error(error);
          throw new Error("Could not save file, please try again later");
        }
      })
  );

  // Set defect types based on displayed file

  useEffect(
    () => {
      const newDefects = {};
      const addDefectType = (type) => {
        if (defects[type] === undefined) {
          newDefects[type] = true;
        } else {
          newDefects[type] = defects[type];
        }
      };

      Object.keys(selectedPointMaps).forEach((selectedPointMap) =>
        (
          (outputFiles.byType[POINT_MAP][selectedPointMap] || {}).defectTypes ||
          []
        ).forEach(addDefectType)
      );
      Object.keys(selectedPolygonMaps).forEach((selectedPolygonMap) =>
        (
          (outputFiles.byType[POLYGON_MAP][selectedPolygonMap] || {})
            .defectTypes || []
        ).forEach(addDefectType)
      );

      setDefects(newDefects);
    },
    // Adding "defects" here creates a circular dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [outputFiles, selectedPointMaps, selectedPolygonMaps]
  );

  // Build defect expression (used in layer filters and color)

  const defectExpression = useMemo(() => {
    const expression = ["any"];

    Object.keys(defects).forEach((defectType) => {
      if (defects[defectType]) {
        expression.push(["in", defectType, ["get", "defectTypes"]]);
      }
    });

    return expression;
  }, [defects]);

  // Build layer filter

  const layerFilter = useMemo(() => {
    // Filter based on severity range
    let filter = [
      "all",
      [
        "<=",
        (minSeverity === "" ? 0 : Number(minSeverity)) / 100,
        ["get", "defectSeverity"],
      ],
      [
        ">=",
        (maxSeverity === "" ? 100 : Number(maxSeverity)) / 100,
        ["get", "defectSeverity"],
      ],
    ];

    // Filter based on enabled defects
    if (!showAllPoints) {
      if (defectExpression.length > 1) {
        filter.push(defectExpression);
      } else {
        // Hide all features if no defects are enabled
        filter = hideFilter;
      }
    }

    return filter;
  }, [defectExpression, minSeverity, maxSeverity, showAllPoints]);

  // Build layer color

  const getLayerColor = useCallback(
    (defaultColor, minDefectColor, maxDefectColor) => {
      let color = defaultColor;
      // Enable defect coloring if any defects are checked
      if (defectExpression.length > 1) {
        // Color enabled defects based on severity
        color = [
          "case",
          // Use special coloring for enabled defects
          defectExpression,
          // Color the defect more intensely based on severity
          [
            "interpolate",
            ["linear"],
            ["get", "defectSeverity"],
            0,
            minDefectColor,
            1,
            maxDefectColor,
          ],
          // Use default coloring otherwise
          color,
        ];
      }
      return color;
    },
    [defectExpression]
  );

  // Build feature selection functions

  const onSetFeature = useCallback((feature, image = 0) => {
    let center;

    const { coordinates, type } = feature.geometry;

    // Find center
    if (type === "Polygon") {
      if (feature.properties.center) {
        center = feature.properties.center;
      } else {
        let long = 0;
        let lat = 0;

        const points = coordinates[0];

        points.forEach((coord) => {
          long += coord[0];
          lat += coord[1];
        });

        long /= points.length;
        lat /= points.length;

        center = [long, lat];
      }
    } else {
      center = coordinates;
    }

    if (popupRef.current) {
      popupRef.current.remove();
    }

    // Create popup
    const popup = new mapboxgl.Popup({
      anchor: "bottom",
      maxWidth: "none",
      closeButton: false,
    })
      .setLngLat(center)
      .setHTML('<div class="report-details-popup"></div>')
      .addTo(mapRef.current);

    popupRef.current = popup;

    // Capture rendered element
    popupElRef.current = popup
      .getElement()
      .getElementsByClassName("report-details-popup")[0];

    // Store the selected feature
    const featureDetails = parseReportFeature(feature);
    setSelectedFeature(featureDetails);
    setSelectedImage(image);

    // Jump to the selected point
    mapRef.current.flyTo({
      center,
      padding: { top: 375 },
      speed: 0.3,
      curve: 0,
    });
  }, []);

  const onSetOutputFileFeature = useCallback(
    (outputFileId, id) => {
      if (!outputFileId || id === selectedFeature.properties.id) {
        return;
      }
      const feature = mapRef.current.querySourceFeatures(outputFileId, {
        filter: ["==", id, ["get", "id"]],
      })[0];
      if (feature) {
        feature.layer = { id: outputFileId };
        setFeatureHistory([
          [...featureHistory[0], [selectedFeature, selectedImage]],
          [],
        ]);
        onSetFeature(feature);
      }
    },
    [featureHistory, onSetFeature, selectedFeature, selectedImage]
  );

  // Build history navigation functions

  const onPrevFeatureHistory = useCallback(() => {
    const [backHistory, forwardHistory] = featureHistory;
    const [feature, image] = backHistory[backHistory.length - 1];
    onSetFeature(feature, image);
    setFeatureHistory([
      backHistory.slice(0, -1),
      [[selectedFeature, selectedImage], ...forwardHistory],
    ]);
  }, [featureHistory, onSetFeature, selectedFeature, selectedImage]);

  const onNextFeatureHistory = useCallback(() => {
    const [backHistory, forwardHistory] = featureHistory;
    const [feature, image] = forwardHistory[0];
    onSetFeature(feature, image);
    setFeatureHistory([
      [...backHistory, [selectedFeature, selectedImage]],
      forwardHistory.slice(1),
    ]);
  }, [featureHistory, onSetFeature, selectedFeature, selectedImage]);

  // Setup pointer events

  useEffect(() => {
    const map = mapRef.current;
    if (loaded && map) {
      const mouseOverCounter = { value: 0 };

      const onMouseEnterFeature = () => {
        mouseOverCounter.value += 1;
        map.getCanvas().style.cursor = "pointer";
      };

      const onMouseLeaveFeature = () => {
        mouseOverCounter.value -= 1;
        if (mouseOverCounter.value === 0) {
          map.getCanvas().style.cursor = "";
        }
      };

      const onClickFeature = ({ features: [feature], originalEvent }) => {
        // Workaround to prevent clicking on layers below this one
        if (originalEvent.cancelBubble) {
          return;
        }
        originalEvent.cancelBubble = true;
        onSetFeature(feature);
        setFeatureHistory([[], []]);
      };

      Object.keys(selectedPointMaps).forEach((selectedPointMap) => {
        const { id: pointId } = outputFiles.byType[POINT_MAP][selectedPointMap];
        map.on("mouseenter", pointId, onMouseEnterFeature);
        map.on("mouseleave", pointId, onMouseLeaveFeature);
        map.on("click", pointId, onClickFeature);
      });

      Object.keys(selectedPolygonMaps).forEach((selectedPolygonMap) => {
        const { id: polygonId } = outputFiles.byType[POLYGON_MAP][
          selectedPolygonMap
        ];
        map.on("mouseenter", polygonId, onMouseEnterFeature);
        map.on("mouseleave", polygonId, onMouseLeaveFeature);
        map.on("click", polygonId, onClickFeature);
      });

      return () => {
        Object.keys(selectedPointMaps).forEach((selectedPointMap) => {
          const { id: pointId } = outputFiles.byType[POINT_MAP][
            selectedPointMap
          ];
          map.off("mouseenter", pointId, onMouseEnterFeature);
          map.off("mouseleave", pointId, onMouseLeaveFeature);
          map.off("click", pointId, onClickFeature);
        });

        Object.keys(selectedPolygonMaps).forEach((selectedPolygonMap) => {
          const { id: polygonId } = outputFiles.byType[POLYGON_MAP][
            selectedPolygonMap
          ];
          map.off("mouseenter", polygonId, onMouseEnterFeature);
          map.off("mouseleave", polygonId, onMouseLeaveFeature);
          map.off("click", polygonId, onClickFeature);
        });
      };
    }
  }, [
    loaded,
    outputFiles,
    onSetFeature,
    selectedPointMaps,
    selectedPolygonMaps,
  ]);

  // Update layers

  useEffect(() => {
    const map = mapRef.current;

    if (!loaded || !map) {
      return;
    }

    // Add polygon layer

    outputFiles.byType[POLYGON_MAP].forEach(({ id }, index) => {
      if (selectedPolygonMaps[index]) {
        map.setFilter(id, layerFilter);
        map.setPaintProperty(
          id,
          "fill-color",
          getLayerColor(
            defaultPolyColor,
            minDefectPolyColor,
            maxDefectPolyColor
          )
        );
      } else {
        map.setFilter(id, hideFilter);
      }
    });

    // Add point layer

    outputFiles.byType[POINT_MAP].forEach(({ id, icon }, index) => {
      if (selectedPointMaps[index]) {
        map.setFilter(id, layerFilter);
        if (!icon) {
          map.setPaintProperty(
            id,
            "circle-color",
            getLayerColor(
              defaultPointColor,
              minDefectPointColor,
              maxDefectPointColor
            )
          );
        }
      } else {
        map.setFilter(id, hideFilter);
      }
    });
  }, [
    loaded,
    outputFiles,
    layerFilter,
    getLayerColor,
    selectedPolygonMaps,
    selectedPointMaps,
  ]);

  // Map initialization logic

  const setMapRef = (map) => {
    // Handle errors

    map.on("error", (e) => {
      console.error(e);
      setError("Mapbox error, please try again later");
    });

    // Set up sources

    map.on("load", (e) => {
      mapRef.current = map;

      [POLYGON_MAP, POINT_MAP].forEach((outputType) =>
        outputFiles.byType[outputType].forEach(({ id, downloadUrl }) =>
          map.addSource(id, {
            type: "geojson",
            data: downloadUrl,
          })
        )
      );

      Promise.all(
        outputFiles.icons.map((icon) =>
          map.loadImage(`/img/icons/${icon}.png`, (error, data) => {
            if (error) {
              console.error("Error loading image:", error);
            } else {
              map.addImage(icon, data);
            }
          })
        )
      ).then(() => {
        outputFiles.byType[POLYGON_MAP].forEach(({ id }) =>
          map.addLayer({
            id,
            type: "fill",
            source: id,
            paint: {
              "fill-color": defaultPointColor,
            },
            filter: hideFilter,
          })
        );

        outputFiles.byType[POINT_MAP].forEach(({ id, icon }) =>
          map.addLayer({
            id,
            source: id,
            filter: hideFilter,
            ...(icon
              ? {
                  type: "symbol",
                  layout: {
                    "icon-image": icon,
                  },
                }
              : {
                  type: "circle",
                  paint: {
                    "circle-radius": reportFeatureCircleRadius,
                    "circle-color": defaultPolyColor,
                  },
                }),
          })
        );

        setLoaded(true);
      });
    });
  };

  return {
    user,
    mapRef,
    setMapRef,
    mapOptions,
    popupRef,
    popupElRef,
    loaded,
    error,
    selectedFeature,
    onSetFeature,
    selectedImage,
    setSelectedImage,
    featureHistory,
    setFeatureHistory,
    onSetOutputFileFeature,
    onPrevFeatureHistory,
    onNextFeatureHistory,
    selectedPointMaps,
    setSelectedPointMaps,
    selectedPolygonMaps,
    setSelectedPolygonMaps,
    defects,
    setDefects,
    showAllPoints,
    setShowAllPoints,
    minSeverity,
    setMinSeverity,
    maxSeverity,
    setMaxSeverity,
    outputFiles,
    gisExport,
  };
}

export { useReportMap };
