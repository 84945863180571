import React from "react";
import {
  Button,
  Container,
  Divider,
  Form,
  Header,
  Icon,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import { reportsUrl } from "utils/urls";
import ReportStatusLabel from "components/ReportStatusLabel";

function ReportRequestDetails({ report: { loading, data } }) {
  return (
    <>
      {data.reasonForFailure ? (
        <Form.Field error>
          <label>Reason for failure</label>
          <Message negative>{data.reasonForFailure}</Message>
        </Form.Field>
      ) : null}
      {data.inputFiles && data.inputFiles.length ? (
        <Form.Field>
          <label>Files to process</label>
          <Segment.Group>
            {data.inputFiles.map(({ name }) => (
              <Segment>
                <Icon name="file" size="large" /> {name}
              </Segment>
            ))}
          </Segment.Group>
        </Form.Field>
      ) : null}
      {data.notes ? (
        <Form.TextArea label="Notes" readonly>
          {data.notes}
        </Form.TextArea>
      ) : null}
    </>
  );
}

ReportRequestDetails.defaultProps = { report: {} };

function ReportRequestDetailsPage({ report }) {
  return (
    <Container text>
      <Header as="h1" textAlign="center">
        Report Request Details
      </Header>
      <Divider hidden section />
      <Header as="h3" textAlign="center">
        {(report.data || {}).title}
      </Header>
      <Form>
        <Segment textAlign="left" padded>
          <ReportStatusLabel
            loading={report.loading}
            report={report.data}
            labelProps={{ attached: "top" }}
          />
          <ReportRequestDetails report={report} />
        </Segment>
      </Form>
      <Divider hidden />
      <Segment textAlign="center" basic>
        <Button as={Link} to={reportsUrl} centered>
          Return to browsing reports
        </Button>
      </Segment>
    </Container>
  );
}

ReportRequestDetailsPage.defaultProps = { report: {} };

function ReportRequestDetailsModal({ report, ...rest }) {
  return (
    <Modal {...rest}>
      <ReportStatusLabel
        loading={report.loading}
        report={report.data}
        labelProps={{ attached: "top" }}
      />
      <Modal.Header>Report Request Details</Modal.Header>
      <Modal.Content>
        <Form>
          <ReportRequestDetails report={report} />
        </Form>
      </Modal.Content>
    </Modal>
  );
}

ReportRequestDetailsModal.defaultProps = { report: {} };

export {
  ReportRequestDetails,
  ReportRequestDetailsPage,
  ReportRequestDetailsModal,
};
