import React, { useEffect } from "react";

import { getUser } from "utils/api";
import { UserContext, useApiCall } from "utils/hooks";

function UserProvider({ children }) {
  const user = useApiCall(getUser, {});

  useEffect(() => {
    if (!user.isAuthenticated || user.loading || user.loaded || user.error) {
      return;
    }

    user.load({ auth0Id: user.auth0User.sub });
  });

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export default UserProvider;

export { UserContext };
