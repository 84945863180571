import "utils/bootstrap";

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Divider, Loader, Message } from "semantic-ui-react";

import { useApiCall } from "utils/hooks";
import { getReport } from "utils/api";
import { DONE } from "utils/reportStatuses";

import { ReportRequestDetailsPage } from "./ReportRequestDetails";
import Report from "./Report";

import "./reportDetails.less";

function ReportDetails() {
  const { id } = useParams();
  const report = useApiCall(getReport);

  // Load report
  useEffect(() => {
    if (!report.isAuthenticated || report.loading) {
      return;
    }

    if (report.loaded && report.lastArgs[0].id === id) {
      return;
    }

    return report.load({ id });
  }, [report, id]);

  const isLoading = !report.loaded || report.loading;

  if (isLoading) {
    return <Loader active size="large" />;
  }

  if (report.error) {
    return (
      <Container text>
        <Divider section hidden />
        <Message negative>
          <Message.Header>
            There was an error retrieving the report
          </Message.Header>
          <p>{(report.error.error || report.error).toString()}</p>
        </Message>
      </Container>
    );
  }

  return report.data.status !== DONE ? (
    <ReportRequestDetailsPage report={report} />
  ) : (
    <Report report={report} />
  );
}

export default ReportDetails;
